<template>

<div class="single_Speed">
    <el-form :model="addForm"  ref="addForm" label-width="150px">

                <el-form-item label="速率">
                    <el-select v-model="addForm.speed" placeholder="请选择" filterable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in speedList" :key='index' :label="item.speedKey" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="addForm.remark"></el-input>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align:right">
                <el-button class="themed-button" type="success" @click="submitForm">确 认</el-button>
                <el-button class="themed-button" type="primary" @click="cancel">取 消</el-button>
            </div>
</div>

</template>

<script type="text/ecmascript-6">

import {findSpeedByIccid,saveSingleLimit} from "@/api/speedLimit/index.js";
import {messageBox} from '@/utils/common.js'

export default {
    props:['iccidMark'],
    data(){
        return {
            addForm:{
               speed:'' ,
               remark:'',
               iccidMark:this.iccidMark,
               accountName:"",
               agentName:"",
               status:"",
            },
            speedList:[],
        }
    },
    created(){
       this.queryIccidInfo()
    },
    methods:{
        // sure(formName) {
        //     this.$refs[formName].validate((valid) => {
        //         if (valid) {
        //             limitStop_(this.form).then((res)=>{
        //                 if(res.slide_msg_key==1){
        //                     messageBox(this,res.slide_msg_message_key);
        //                 }
        //             })
        //         } else {
        //             console.log('error submit!!');
        //             return false;
        //         }
        //     });
        // },
        // reset(formName){
        //     this.$refs[formName].resetFields();
        // },
        // close(){
        //     this.$emit("close")
        // }
        queryIccidInfo(){
            findSpeedByIccid({iccidMark:this.addForm.iccidMark}).then(res=>{
                this.speedList = res.speedList
                this.addForm.accountName = res.accountName
                this.addForm.agentName = res.agentName
                this.addForm.status = res.statusStr
            })
        },
         submitForm(){
            if( !this.addForm.speed){
                messageBox(this,'请选择速率')
                return
            }
            saveSingleLimit(this.addForm).then(res=>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                    });
                this.$emit("close")
            })
        },
        cancel(){
           this.$emit("close") 
        }

    }
}
</script>
