import request from '../request.js'


// 限速规则
export function limitRuleList(params) {
    return request({
        url: '/speedLimitRule/list.do?',
        method: 'get',
        params: params
    })
}
export function limitRuleListSeal(params) {//新增套餐界面的接口变更
    return request({
        url: '/speedLimitRule/getSpeedLimitRule.do?',
        method: 'get',
        params: params
    })
}



export function addLimitRule(params) {
    return request({
        url: '/speedLimitRule/add.do?',
        method: 'post',
        data: params,
    })
}

export function findLimitRuleById(params) {
    return request({
        url: '/speedLimitRule/findOne.do?',
        method: 'post',
        data: params,
    })
}

export function editLimitRule(params) {
    return request({
        url: '/speedLimitRule/edit.do?',
        method: 'post',
        data: params,
    })
}

export function deleteLimitRule(params) {
    return request({
        url: '/speedLimitRule/delete.do?',
        method: 'get',
        params: params,
    })
}

export function exportLimitRule(params) {
    return request({
        url: '/speedLimitRule/export.do?',
        method: 'post',
        data: params,
    })
}



// 单卡限速
export function singleCardList(params) {
    return request({
        url: '/cardLimit/list.do?',
        method: 'post',
        data: params
    })
}

export function exportSingleCardLimit(params) {
    return request({
        url: '/cardLimit/export.do?',
        method: 'post',
        data: params
    })
}

export function findSpeedByIccid(params) {
    return request({
        url: '/cardLimit/findSpeedListByIccid.do?',
        method: 'post',
        data: params
    })
}

export function saveSingleLimit(params) {
    return request({
        url: '/cardLimit/save.do?',
        method: 'post',
        data: params
    })
}

export function deleteSingleLimit(params) {
    return request({
        url: '/cardLimit/delete.do?',
        method: 'post',
        data: params
    })
}

export function importSingleLimitByExcel(params) {
    return request({
        url: '/cardLimit/import.do?',
        method: 'post',
        data:params,
        timeout:60000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


// 单卡历史记录
export function singleCardHistoryList(params) {
    return request({
        url: '/cardLimit/history/list.do?',
        method: 'post',
        data: params
    })
}

export function exportSingleCardHistory(params) {
    return request({
        url: '/cardLimit/history/export.do?',
        method: 'post',
        data: params
    })
}


//一次性限速列表

export function singleSpeedList(params) {
    return request({
        url: '/speedLimitOnce/list.do?',
        method: 'post',
        data: params
    })
}

export function importAddSyncSingleSpeed(params) {
    return request({
        url: '/speedLimitOnce/import.do?',
        method: 'post',
        data:params,
        timeout:60000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function exportSingleSpeedLimit(params) {
    return request({
        url: '/speedLimitOnce/export.do?',
        method: 'post',
        data: params
    })
}











