<template>
<div class="gate-setting">
    <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="断网阈值：" required>
            <el-input v-model="form.flowStop"></el-input>
            <p>（只能输入数字，单位：M，-1表示无限制）</p>
        </el-form-item>    
        <el-form-item>
            <el-button type="primary" class="themed-button" @click="sure('form')">确定</el-button>
            <el-button type="primary" class="themed-button" @click="reset('form')">重置</el-button>
            <el-button type="primary" class="themed-button" @click="close">取消</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script type="text/ecmascript-6">
import {singleCardStop} from '@/api/cardManagement/cardBillDetail.js'

import {messageBox} from '@/utils/common.js'

export default {
    props:['iccidMark','flowStop'],
    data(){
        return {
            form:{
               flowStop:this.flowStop ,
               iccidMark:this.iccidMark
            },
            rules:{
                flowStop:[
                        { required: true, message: '断网阈值不能为空', trigger: 'blur' }
                    ]
                },
        }
    },
    methods:{
        sure(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    singleCardStop(this.form).then((res)=>{
                        if(res.slide_msg_key==1){
                            messageBox(this,res.slide_msg_message_key)
                            return
                        }
                        messageBox(this,'设置成功')
                        this.$emit("close")
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        reset(formName){
            this.$refs[formName].resetFields();
        },
        close(){
            this.$emit("close")
        }
    }
}
</script>
